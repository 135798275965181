import React from 'react';
import { Root } from './style';

import { useState } from '@hookstate/core';

const Teams = ({groups, first}) => {
  groups = groups.split(/\n\s*\n/).map((group, index) => {
    const lines = group.split(/\n/);

    return {
      name: lines.shift(),
      members: lines,
    };
  });

  const state = useState(groups.reduce((object, {name}) => {
    object[name] = first;
    return object;
  }, {}));

  return (
    <Root>
      {groups.map(({name, members}) => {
        return <div key={name} className={state[name].get() ? 'open' : ''}>
          <h4 onClick={() => state[name].set(!state[name].get())}>{name}</h4>
          {members.map(member => <span key={member}>{member}</span>)}
        </div>;
      })}
    </Root>
  );
};

export default Teams;
