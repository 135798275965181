import styled from 'styled-components';

import vars from 'variables';

import background from 'assets/AboutUs/TeamLeaders/background.png';
import border from 'assets/AboutUs/TeamLeaders/border.svg';

const Root = styled.div`
  background: ${vars.colors.marineBlue};

  strong, span {
    display: block;
    line-height: 150%;
  }

  strong {
    text-transform: uppercase;
  }

  span {
    color: #AAA;
  }

  a {
    margin-right: 4px;
    position: relative;
    z-index: 1;
  }

  .profile {
    background: url(${background}) no-repeat;
  }

  .photo {
    border-radius: 340px;
  }

  ${vars.desktop.mediaQuery} {
    & {
      padding: 85px 0 125px;
    }

    section {
      display: flex;
    }

    h3 {
      margin-bottom: -40px;
      font-size: 1.65rem;
    }

    span {
      font-size: 0.85rem;
    }

    .profile {
      width: 450px;
      margin-left: -177px;
      margin-bottom: -40px;
      padding-top: 485px;
      padding-left: 177px;
      position: relative;
      background-position: 85px 0;
    }

    .photo {
      top: 105px;
      left: 168px;
      width: 340px;
      height: 340px;
      position: absolute;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      margin-bottom: 60px;
      padding: 50px 0;
    }

    section {
      width: 100vw;
      margin-left: -20px;
      margin-bottom: 10px;
      padding-bottom: 40px;
      background: url(${border}) no-repeat bottom center;
      background-size: 85%;
    }

    section:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      background: none;
    }

    h3 {
      font-size: 1.2rem;
      font-weight: 600;
    }

    strong {
      font-size: 0.8rem;
      font-weight: 600;
    }

    .profile {
      margin: -16px 0 -35px;
      padding: 50px 23px;
      display: flex;
      background-position: -26px -13px;
      background-size: 280px;
    }

    .details {
      padding-left: 25px;
      align-self: center;
    }

    .details span {
      padding: 4px 0 8px;
      font-size: 0.7rem;
      font-weight: 400;
      display: block;
    }

    .photo {
      width: 185px;
    }
  }
`;

export { Root };
