import styled from "styled-components";

import vars from "variables";

const Root = styled.div`
  margin-bottom: 50px;

  &.reverse {
    flex-direction: row-reverse;
  }

  p a {
    color: ${vars.colors.blue};
    font-weight: bold;
  }

  a.button {
    margin-top: 15px;
    margin-right: 8px;
  }

  ${vars.desktop.mediaQuery} {
    & {
      padding-top: 50px;
      display: flex;
      align-items: center;
    }

    &.reverse p {
      padding-right: 60px;
    }

    &.reverse .wrapper {
      padding: 90px 40px 90px 100px;
    }

    .wrapper {
      padding: 90px 120px 90px 0;
    }

    h2 {
      margin: 10px 0 35px;
      font-size: 3.5rem;
    }

    p {
      margin: 30px 0;
      color: ${vars.colors.grey};
      line-height: 165%;
    }

    a.button {
      padding-left: 45px;
      padding-right: 45px;
    }

    a.button.logo,
    a.button.logo-x {
      padding-left: 70px;
      background-position: 40px;
    }

    img {
      width: 43%;
      height: 43%;
    }

    img.logo {
      width: 88px;
      height: 88px;
      margin-bottom: 17px;
    }
  }

  ${vars.mobile.mediaQuery} {
    h2 {
      margin-top: 3px;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 140%;
    }

    p {
      margin: 15px 0;
    }

    a.button:first-child {
      margin-top: 35px;
    }

    img {
      margin-top: 50px;
      margin-bottom: -15px;
      width: 100%;
    }

    img.logo {
      width: 45px;
      height: 45px;
      margin-bottom: 12px;
    }
  }
`;

export { Root };
