import styled from "styled-components";

import vars from "variables";

import topRight from "assets/Common/PageHeader/top-right.svg";

const Root = styled.div`
  margin: 0 auto;
  text-align: center;

  .highlight {
    padding: 2px;
    border-radius: 25px;
    background: rgb(0, 42, 84);
  }

  .highlight strong {
    height: 29px;
    margin-left: -16px;
    margin-right: 7px;
    padding: 0 12px;
    color: white;
    display: inline-block;
    border-radius: 50px;
    background: ${vars.colors.blue};
  }

  .highlight a {
    text-decoration: underline;
  }

  a.button {
    margin-top: 40px;
    padding: 18px 20px;
  }

  img {
    width: 100%;
    margin-bottom: 40px;
    border-radius: 20px;
  }

  ${vars.desktop.mediaQuery} {
    & {
      padding-top: 100px;
    }

    .highlight {
      margin-bottom: 50px;
      display: inline-flex;
      font-size: 0.9rem;
      line-height: 200%;
    }

    .highlight > div {
      padding: 0 15px;
    }

    h1 {
      margin-top: 7px;
      margin-bottom: 20px;
      font-size: 3.5rem;
    }

    p {
      padding: 0 290px;
      color: ${vars.colors.grey};
      font-size: 1rem;
    }

    img {
      margin-top: 100px;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      padding-top: 20px;
    }

    .highlight {
      margin-bottom: 30px;
      display: flex;
    }

    .highlight strong {
      height: 21px;
      margin-right: 8px;
    }

    h1 {
      margin-top: 5px;
      margin-bottom: 10px;
      font-size: 1.75rem;
      line-height: 135%;
    }

    img {
      height: 220px;
      margin-top: 40px;
      object-fit: cover;
    }
  }
`;

export { Root };
