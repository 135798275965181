import styled from 'styled-components';

import vars from 'variables';

import up from 'assets/AboutUs/TeamLeaders/Teams/up.svg';
import down from 'assets/AboutUs/TeamLeaders/Teams/down.svg';
import line from 'assets/AboutUs/TeamLeaders/Teams/line.svg';

const Root = styled.div`
  h4 {
    font-size: 0.94rem;
    line-height: 155%;
  }

  ${vars.desktop.mediaQuery} {
    & {
      width: 320px;
      padding-top: 80px;
    }

    h4, span {
      padding-left: 8px;
    }

    h4 {
      margin-top: 45px;
      margin-bottom: 7px;
      background: url(${line}) no-repeat bottom left;
    }

    span {
      font-size: 1.04rem;
      line-height: 155%;
    }
  }

  ${vars.mobile.mediaQuery} {
    h4 {
      margin-top: 25px;
      margin-bottom: 8px;
      font-size: 0.75rem;
      letter-spacing: 0.25px;
      background: url(${down}) no-repeat center right;
    }

    .open h4 {
      background-image: url(${up});
    }

    div {
      padding: 0 22px;
    }

    span {
      display: none;
      font-size: 0.875rem;
      line-height: 185%;
    }

    .open span {
      display: block;
    }
  }
`;

export { Root };
