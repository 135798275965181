import React from 'react';
import { Root } from './style';

import SiteData from 'components/Common/SiteData';
import SiteImage from 'components/Common/SiteImage';

import hodl from 'assets/Common/SiteSection/hodl.svg';

const SiteSection = ({ path, image, reverse, logo ,imageClass}) => {
  return (
    <Root className={`site-section body-width ${reverse ? 'reverse' : ''}`}>
      <div className="wrapper">
        { logo ? <img className="logo" src={hodl}/> : '' }
        <SiteData path={path}/>
      </div>
      <SiteImage className={imageClass} path={`${path.replace(/\.[^\.]+$/, '')}.${image}`}/>
    </Root>
  );
};

export default SiteSection;
