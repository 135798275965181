import styled from 'styled-components';

import vars from 'variables';

import border from 'assets/Common/Borders/horizontal.svg';

const Root = styled.div`
  p {
    color: ${vars.colors.white};
  }

  div {
    padding: 25px 0;
    display: flex;
    justify-content: space-between;
    background-image: url(${border}),
                      url(${border});
    background-repeat: no-repeat,
                       no-repeat;
    background-position: top center,
                         bottom center;
    background-size: contain,
                     contain;
  }

  div span {
    display: flex;
    justify-content: center;
  }

  div span img {
    filter: brightness(0) invert(1);
  }

  ${vars.desktop.mediaQuery} {
    & {
      margin-top: 100px;
      margin-bottom: 100px;
    }

    p {
      padding-bottom: 12px;
      font-size: 0.925rem;
      text-align: center;
    }

    div span {
      margin-right: 50px;
    }

    div span img {
      width: 80%;
    }
  }

  ${vars.mobile.mediaQuery} {
    & {
      margin-top: 70px;
      margin-bottom: 70px;
      text-align: center;
    }

    p {
      padding-bottom: 6px;
    }

    div span {
      min-width: 0;
    }

    div span img {
      max-width: 60%;
    }
  }
`;

export { Root };
