import React from "react";
import { Root } from "./style";

import PageHeader from "components/Common/PageHeader";
import FeaturedBy from "components/Common/FeaturedBy";
import ReadyToStart from "components/Common/ReadyToStart";
import SiteSection from "components/Common/SiteSection";

import TeamLeaders from "./TeamLeaders";

const AboutUs = ({ pageContext: { teamLeaders } }) => {
  return (
    <Root>
      <PageHeader page="aboutUs" />
      <SiteSection
        path="aboutUs.codeSection1"
        image="imageSection1"
        reverse={true}
      />
      <SiteSection path="aboutUs.codeSection2" image="imageSection2" />
      <FeaturedBy />
      <TeamLeaders teamLeaders={teamLeaders} />
      <SiteSection path="aboutUs.codeSection3" image="imageSection3" />
      <SiteSection path="aboutUs.codeSection4" image="imageSection4" />
      <ReadyToStart />
    </Root>
  );
};

export default AboutUs;
