import React from 'react';
import { Root } from './style';

import SiteImage from 'components/Common/SiteImage';

import twitter from 'assets/AboutUs/TeamLeaders/twitter.svg';
import linkedIn from 'assets/AboutUs/TeamLeaders/linked-in.svg';

import Teams from './Teams';

const TeamLeaders = ({ teamLeaders }) => {

  return (
    <Root>
      <div className="body-width">
        <h3>
          <a id="our-team"></a>
          Our team
        </h3>
        {(teamLeaders || []).map(teamLeader =>
          <section key={ teamLeader.id }>
            <div className="profile">
              <SiteImage className="photo" image={teamLeader.image}/>
              <div className="details">
                <strong>{ teamLeader.name }</strong>
                <span>{ teamLeader.title }</span>
                {
                  teamLeader.twitterHandle ?
                    <a href={ `https://twitter.com/${teamLeader.twitterHandle}` } target="_blank" rel="noreferrer">
                      <img src={ twitter }/>
                    </a> :
                    ''
                }
                {
                  teamLeader.linkedInHandle ?
                    <a href={ `https://linkedin.com/in/${teamLeader.linkedInHandle}` } target="_blank" rel="noreferrer">
                      <img src={ linkedIn }/>
                    </a> :
                    ''
                }
              </div>
            </div>
            <Teams groups={teamLeader.teamColumn1} first={true}/>
            <Teams groups={teamLeader.teamColumn2}/>
            <Teams groups={teamLeader.teamColumn3}/>
          </section>
        )}
      </div>
    </Root>
  );
};

export default TeamLeaders;
