import React from 'react';
import { Root } from './style';

import inc from 'assets/Common/FeaturedBy/inc.svg';
import benzinga from 'assets/Common/FeaturedBy/benzinga.svg';
import coinmarketcap from 'assets/Common/FeaturedBy/coinmarketcap.svg';
import crunchbase from 'assets/Common/FeaturedBy/crunchbase.svg';
import businessInsider from 'assets/Common/FeaturedBy/business-insider.svg';
import yahoo from 'assets/Common/FeaturedBy/yahoo.svg';

const FeaturedBy = () => {
  return (
    <Root className="body-width">
      <p>Featured by</p>
      <div>
        <span><img src={crunchbase} className="desktop"/></span>
        <span><img src={yahoo}/></span>
        <span><img src={benzinga}/></span>
        <span><img src={businessInsider}/></span>
        <span><img src={coinmarketcap} className="desktop"/></span>
        <span><img src={inc}/></span>
      </div>
    </Root>
  );
};

export default FeaturedBy;
