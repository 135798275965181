import styled from 'styled-components';

import vars from 'variables';

const Root = styled.div`

  ${vars.desktop.mediaQuery} {
    .page-header {
      margin-top: 25px;
    }

    .page-header h1 {
      width: 900px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  ${vars.mobile.mediaQuery} {
    /* styling */
  }
`;

export { Root };
