import React from "react";
import { Root } from "./style";

import { useSiteData } from "utils";
import capitalize from "lodash/capitalize";

import RawHTML from "components/Common/RawHTML";
import SiteImage from "components/Common/SiteImage";

const PageHeader = (props) => {
  const { pages } = useSiteData();

  let { className, title, subtitle, match, intro, image, rawSubtitle } = props;

  if (props.page) {
    let page = props.page;

    if (typeof page === "string") {
      page = pages[page];
    }

    title = page.title;
    subtitle = page.subtitle;
    match = ("" + subtitle).match(/^\[([A-Z]+)\] (.*)/);
    intro = page.intro;
    image = page.image;
  }

  return (
    <Root className={`body-width page-header ${className || ""}`}>
      {rawSubtitle ? (
        <div className="highlight">
          <RawHTML html={rawSubtitle} />
        </div>
      ) : subtitle ? (
        match ? (
          <div className="highlight">
            <strong>{match[1]}</strong>
            <RawHTML
              html={match[2].replace(
                /(https?:\/\/([^\/]+)\S+)/,
                (_, href, domain) =>
                  `<a href="${href}" target="_blank" rel="noreferrer">${capitalize(
                    domain
                  )}</a>`
              )}
            />
          </div>
        ) : (
          <h4>
            <span>{subtitle}</span>
          </h4>
        )
      ) : (
        ""
      )}
      <h1>
        {title ? (
          title.match(/<(.*?)>.*?<\/\1>/s) ? (
            <RawHTML html={title} />
          ) : (
            title
          )
        ) : (
          ""
        )}
      </h1>
      {intro ? (
        intro.match(/<(.*?)>.*?<\/\1>/s) ? (
          <RawHTML html={intro} />
        ) : (
          <p>{intro}</p>
        )
      ) : (
        ""
      )}
      {image ? <SiteImage image={image} /> : ""}
    </Root>
  );
};

export default PageHeader;
